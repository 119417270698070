import { FC, useMemo } from 'react';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import {
  AlgoliaEventsCalendar,
  AlgoliaEventsCalendarProps,
} from '../../algolia/AlgoliaEventsCalendar';
import { CreateEventButton } from '../CreateEventButton';
import { SelectableEventWrapper } from './SelectableEventWrapper';
import { memo } from '../../../util/memo';
import { useUserEventsFilters } from '../../../hooks/calendar/selectable-event/useUserEventsFilters';
import { SQUARE_300_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';
import { useAdInjection } from '../../../hooks/ads/useAdInjection';
import { EventHit } from '../../algolia/catalog-wrappers/EventsCalendar';
import { CALENDAR_AD } from '../../../../functions/src/util/ads/adIds';

export type SelectableEventsCalendarProps = Omit<
  AlgoliaEventsCalendarProps,
  | 'Wrapper'
  | 'configureOptions'
  | 'TitleRight'
  | 'initialDate'
  | 'transformedHits'
  | 'Extension'
>;

const SELECT_CALENDAR_AD_CONFIG = {
  id: CALENDAR_AD,
  ...SQUARE_300_AD_INJECTION_CONFIG,
} as const;

const SelectableEventsCalendarUnmemoized: FC<SelectableEventsCalendarProps> = ({
  description,
  height = '520px',
  ...props
}) => {
  const [calendarDateParam] = useRouterState({
    key: 'eventDate',
    silent: true,
  });

  const calendarDate = useMemo(() => {
    return calendarDateParam && !isNaN(Number(calendarDateParam))
      ? new Date(Number(calendarDateParam))
      : undefined;
  }, [calendarDateParam]);

  const filters = useUserEventsFilters();

  const configureOptions = useMemo(() => {
    return {
      filters,
    };
  }, [filters]);

  const injectAdsMain = useAdInjection<EventHit<Date>>(
    SELECT_CALENDAR_AD_CONFIG,
  );
  const injectAdsExtension = useAdInjection<EventHit<Date>>(
    SELECT_CALENDAR_AD_CONFIG,
  );

  const createEventButton = useMemo(() => {
    return <CreateEventButton />;
  }, []);

  return (
    <AlgoliaEventsCalendar
      {...props}
      Wrapper={SelectableEventWrapper}
      transformHits={injectAdsMain}
      transformHitsExtension={injectAdsExtension}
      configureOptions={configureOptions}
      description={description}
      SearchRight={createEventButton}
      initialDate={calendarDate}
      height={height}
    />
  );
};

export const SelectableEventsCalendar = memo(
  SelectableEventsCalendarUnmemoized,
);
