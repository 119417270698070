import { FC, ReactNode, useMemo, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { GliderContainer, ReactGlider } from '../../ReactGlider';
import { GradientIconButton } from '../../gradients/GradientIconButton';

import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { KeyedNode } from '../../../../functions/src/types/Hit';
import { useNearEndObserver } from '../../../hooks/visibility/useNearEndObserver';

export const HORIZONTAL_CAROUSEL_BUFFER = 8;

export type HorizontalCarouselProps = {
  Content: KeyedNode[];
  itemWidth: number;
  minHeight: string | number;
  header?: ReactNode;
  onNearEnd?: () => void;
};

const HorizontalCarouselUnmemoized: FC<HorizontalCarouselProps> = ({
  Content,
  itemWidth,
  minHeight,
  header,
  onNearEnd,
}) => {
  const nearEndRef = useNearEndObserver({ onNearEnd });

  const MemoizedContent = useMemo(() => {
    return (
      <>
        {Content.map(({ key, Node }, index) => {
          return (
            <Box
              ref={
                index === Content.length - HORIZONTAL_CAROUSEL_BUFFER
                  ? nearEndRef
                  : null
              }
              key={key}
            >
              {Node}
            </Box>
          );
        })}
      </>
    );
  }, [Content, nearEndRef]);

  const gliderRef = useRef<GliderContainer>(null);

  const theme = useTheme();

  const shiftLeft = useCallback(() => {
    gliderRef.current?.shiftCursor(-1);
  }, [gliderRef]);

  const shiftRight = useCallback(() => {
    gliderRef.current?.shiftCursor(1);
  }, [gliderRef]);

  const iconButtonStyle = {
    backgroundColor: theme.palette.action.background,
    borderRadius: '100px',
    padding: '8px',
    width: '40px',
    height: '40px',
  };

  return (
    <Box>
      <Stack direction="column" position="relative">
        <Stack direction="row" alignItems="center">
          {header}
          <Box sx={{ marginLeft: 'auto' }}>
            <Stack direction="row" spacing={1}>
              <GradientIconButton
                IconComponent={ChevronLeftIcon}
                gradientColor="primary.vertical"
                onClick={shiftLeft}
                sx={iconButtonStyle}
              />
              <GradientIconButton
                IconComponent={ChevronRightIcon}
                gradientColor="primary.vertical"
                onClick={shiftRight}
                sx={iconButtonStyle}
              />
            </Stack>
          </Box>
        </Stack>
        <Box
          sx={{
            '& .glider-track': {
              width: `${itemWidth * Content.length}px !important`,
              minHeight,
            },
          }}
        >
          <ReactGlider
            itemWidth={itemWidth}
            hasArrows={false}
            formatContainer={true}
            gliderRef={gliderRef}
          >
            {MemoizedContent}
          </ReactGlider>
        </Box>
      </Stack>
    </Box>
  );
};

export const HorizontalCarousel = memo(HorizontalCarouselUnmemoized);
